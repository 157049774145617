<template>
    <div class="content_group" v-if="erjilist.length>0&&showflag">
        <div class="item" v-for="(item,index) in listdata" :key="index">
            <titlePic :parameter="{ 'text': item.name, 'link': '/secondList?id='+item.id+'&name='+father+'>'+item.name }" />
            <ul class="font19 color-33 sethei" v-if="item.name!='全体会议'">
                <li v-for="(item2,index2) in item.children" :key="item2.id" @click="goxq(father+'>'+item.name,item2)">
                    <div class="dian"></div>
                    <p class="titlename">{{item2.name}}</p>
                </li>
				<span v-show="item.children.length==0">暂无内容</span>
            </ul>
			<div v-if="item.name=='全体会议'" style="padding: 0.21rem 0.67rem 0.14rem 0.37rem;">
				<div style="color: rgb(61,126,208);font-size: 0.22rem;font-weight: bold;">
					第十五届三次
				</div>
				<ul class="font19 color-33 sethei">
				    <li v-for="(item2,index2) in huiyi153" :key="item2.id" @click="goxq('专题专栏>全体会议',item2)">
				        <div class="dian"></div>
				        <p class="titlename">{{item2.name}}</p>
				    </li>
					<span v-show="huiyi153.length==0">暂无内容</span>
				</ul>
				<div style="color: rgb(61,126,208);font-size: 0.22rem;font-weight: bold;">
					第十五届四次
				</div>
				<ul class="font19 color-33 sethei">
				    <li v-for="(item2,index2) in huiyi154" :key="item2.id" @click="goxq('专题专栏>全体会议',item2)">
				        <div class="dian"></div>
				        <p class="titlename">{{item2.name}}</p>
				    </li>
					<span v-show="huiyi154.length==0">暂无内容</span>
				</ul>
			</div>
        </div>
    </div>
</template>

<script>
	import titlePic from "@/components/title_pic.vue"
	export default{
		components: {
		    titlePic
		},
		props:{
			father:{
				type:String,
				default:''
			},
			erjilist:{
				type:Array,
				default:[]
			}
		},
		created() {
			this.listdata = JSON.parse(JSON.stringify(this.erjilist))
			this.listdata.forEach((item,index)=>{
				this.$post({
					url: '/api/news/index',
					params: {
						id: item.id,
						p: 1,
						size: 5
					}
				}).then((res) => {
					this.$set(this.listdata[index], 'children', res.list);
					if(index==(this.listdata.length-1)){
						this.showflag = true
					}					
				})
			})
			this.$post({
				url: '/api/news/index',
				params: {
					id: 33,
					p: 1,
					size: 5
				}
			}).then((res) => {
				this.huiyi153 = res.list				
			})
			this.$post({
				url: '/api/news/index',
				params: {
					id: 34,
					p: 1,
					size: 5
				}
			}).then((res) => {
				this.huiyi154 = res.list				
			})
		},
		data() {
			return{
				listdata:[],
				showflag:false,
				huiyi153:[],
				huiyi154:[]
			}
		},
		methods:{
			goxq(name,item){
				if(item.tzurl){
					window.open(item.tzurl,"_blank")
					return
				}
				this.$router.push('/secondList?id=19&name='+name+'&detail_id='+item.id)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.sethei{
			min-height: 1.38rem;
			background-color: #fff;
		}
	.content_group {
		width: 100%;
	    .item {
	        width: 100%;
	        // border: 0.01rem solid #eee;
	        margin-bottom: .32rem;
	
	        ul {
	            padding: .21rem .67rem .14rem .37rem;
	            box-sizing: border-box;
	
	            li {
	                display: flex;
	                align-items: center;
	
	                .dian {
	                    width: 5px;
	                    height: 5px;
	                    background: #2C65AF;
	                    margin-right: .21rem;
	                }
	
	                p {
	                    overflow: hidden;
	                    white-space: nowrap;
	                    text-overflow: ellipsis;
						width: 98%;
	                }
	            }
	        }
	    }
	}
	.titlename{
		cursor: pointer;
	}
	.titlename:hover{
		color: #2C65AF;
	}
</style>