<template>
    <div class="content_group">
        <div class="item">
            <titlePic :parameter="{ 'text': '第十五届三次', 'link': '/secondList?id=33&name=专题专栏>全体会议>第十五届三次'}"/>
            <ul class="font19 color-33 sethei">
                <li v-for="(item2,index2) in huiyi153" :key="item2.id" @click="goxq('专题专栏>全体会议',item2)">
                    <div class="dian"></div>
                    <p class="titlename">{{item2.name}}</p>
                </li>
				<span v-show="huiyi153.length==0">暂无内容</span>
            </ul>
			
			
			<titlePic :parameter="{ 'text': '第十五届四次', 'link': '/secondList?id=34&name=专题专栏>全体会议>第十五届四次'}"/>
			<ul class="font19 color-33 sethei">
			    <li v-for="(item2,index2) in huiyi154" :key="item2.id" @click="goxq('专题专栏>全体会议',item2)">
			        <div class="dian"></div>
			        <p class="titlename">{{item2.name}}</p>
			    </li>
				<span v-show="huiyi154.length==0">暂无内容</span>
			</ul>
        </div>
    </div>
</template>

<script>
	import titlePic from "@/components/title_pic.vue"
	export default{
		components: {
		    titlePic
		},		
		data() {
			return{
				listdata:[],
				showflag:false,
				huiyi153:[],
				huiyi154:[],
				nowpage:1,
				total:0,
				nowpage2:1,
				total2:0
			}
		},
		created() {
			this.getlist()
			this.getlist2()
			
		},
		methods:{
			getlist(){
				this.$post({
					url: '/api/news/index',
					params: {
						id: 33,
						p: 1,
						size: 10
					}
				}).then((res) => {
					this.total = res.count
					this.huiyi153 = res.list				
				})
			},
			getlist2(){
				this.$post({
					url: '/api/news/index',
					params: {
						id: 34,
						p: this.nowpage2,
						size: 10
					}
				}).then((res) => {
					this.total2 = res.count
					this.huiyi154 = res.list				
				})
			},
			goxq(name,item){
				if(item.tzurl){
					window.open(item.tzurl,"_blank")
					return
				}
				this.$router.push('/secondList?id=23&name='+name+'&detail_id='+item.id)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.sethei{
			min-height: 1.38rem;
			background-color: #fff;
		}
	.content_group {
		width: 100%;
	    .item {
	        width: 100%;
	        // border: 0.01rem solid #eee;
	        margin-bottom: .32rem;
	
	        ul {
	            padding: .21rem .67rem .14rem .37rem;
	            box-sizing: border-box;
	
	            li {
	                display: flex;
	                align-items: center;
	
	                .dian {
	                    width: 5px;
	                    height: 5px;
	                    background: #2C65AF;
	                    margin-right: .21rem;
	                }
	
	                p {
	                    overflow: hidden;
	                    white-space: nowrap;
	                    text-overflow: ellipsis;
						width: 98%;
	                }
	            }
	        }
	    }
	}
	.titlename{
		cursor: pointer;
	}
	.titlename:hover{
		color: #2C65AF;
	}
</style>