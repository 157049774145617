<template>
	<div class="content" style="padding: 10px 4px;">
		<div style="width: 100%;height: 15px;background-color: #78BBE5;"></div>
		<div style="width: 100%;display: flex;align-items: center;">
			<div class="echbox" style="width: 62%;height: 9rem;position: relative;" ref="myechart">
				<img src="../../../assets/echartbg.png" alt=""
					style="position: absolute;width: 100%;height: 100%;left: 0;top: 0;" />
			</div>
			<div
				style="width: 38%;height: 9rem;position: relative;padding: 5px 14px;overflow-y: auto;background-color: rgb(222,239,246);">
				<img src="../../../assets/echarts_rightbg.png" alt=""
					style="position: absolute;width: 100%;height: 100%;left: 0;top: 0;" />


				<div v-for="item in listdata" @click="godetail(item)"
					style="display: flex;align-items: center;position: relative;z-index: 5;margin: 15px 0;cursor: pointer;">
					<img :src="item.image.fullurl" alt="" style="width: 1.76rem;height: 1.28rem;overflow: hidden;" />
					<div style="display: flex;flex-direction: column;margin-left: 0.2rem;width: 50%;">
						<span style="font-size: 0.2rem;color: #333;font-weight: bold;">{{item.name}}</span>
						<p style="font-size: 0.16rem;color: #333;margin: 0;margin-top: 8px;">
							{{item.address}}
						</p>
					</div>
				</div>


			</div>
		</div>
		<div style="width: 100%;height: 15px;background-color: #78BBE5;"></div>
	</div>
</template>

<script>
	import mapdata from '../../../assets/xns.json';
	export default {
		data() {
			return {
				listdata: []
			}
		},
		mounted() {
			this.initChart();

		},
		methods: {
			initChart() {
				this.$post({
					url: '/api/stage_pc/stage_list',
					params: {
						p: 1,
						size: 999
					}
				}).then((res) => {
						this.listdata = res.list


						let dianlist = []
						res.list.forEach((item, index) => {
							let obj = {
								name: item.name,
								value: item.lng_lat.split(','),
								id: item.id
							}
							dianlist.push(obj)
						})
						dianlist = dianlist.reverse()


						// 初始化图表
						let myChart = this.$echarts.init(this.$refs.myechart);
						var option = null;
						var uploadedDataURL = "https://www.isqqw.com/asset/get/areas_v3/city/510100_full.json";
						// 散点图数据
						var scatterData = [{
								name: '',
								num: 16,
								color: ['#07d0bf', '#0f93a3'],
								img: '//img.isqqw.com/profile/upload/2023/10/31/797bebc0-c350-46df-b680-27eaa03223bf.png',
								data: dianlist,
							},

						]
						let serData = []
						// 散点图配置
						scatterData.forEach((item) => {
							serData.push({
								name: `${item.name}  ${item.num}`,
								type: 'effectScatter',
								coordinateSystem: 'geo',
								effectType: 'ripple',
								showEffectOn: 'render',
								// 散点样式
								rippleEffect: {
									period: 1,
									scale: 2,
									brushType: 'fill'
								},
								// 散点大小 自定义散点图片

								symbolSize: [15, 15],
								hoverAnimation: true,
								// 标志的样式
								itemStyle: {
									normal: {
										color: '#fff',
										shadowBlur: 10,
										shadowColor: '#333'
									}
								},
								label: {
									normal: {
										formatter: '{b}',
										position: 'top',
										show: false,
										color: "#fff",
										fontSize: 14,
										distance: -2,
										backgroundColor: {
											type: 'linear',
											x: 1,
											y: 1,
											r: 0.5,
											colorStops: [{
												offset: 0,
												color: item.color[0],
											}, {
												offset: 1,
												color: item.color[1],
											}]
										},
										padding: 5,
									}
								},
								zlevel: 99,
								data: item.data,
							})
						})
						let bgimg = require('../../../assets/echartbg.png')
						this.$echarts.registerMap('js', mapdata);
						option = {

							legend: {
								show: false,
								left: "5%",
								bottom: "5%",
								orient: "vertical",
								itemHeight: 30, //修改icon图形大小
								itemWidth: 30, //修改icon图形大小
								textStyle: {
									color: "#fdff",
									fontSize: 12,
								},
							},
							geo: [{
								map: 'js',
								roam: false, //是否允许缩放
								zoom: 1.1, //默认显示级别
								scaleLimit: {
									min: 0.5,
									max: 3
								}, //缩放级别
								itemStyle: {
									normal: {
										areaColor: '#3894ec',
										borderColor: '#3fdaff',
										borderWidth: 2,
										shadowColor: 'rgba(63, 218, 255, 0.5)',
										shadowBlur: 30
									},
									emphasis: {
										areaColor: '#2B91B7',
									}
								},
								label: {
									show: true,
									color: '#ffffff',
									emphasis: {
										color: '#020933',
									}
								},
								tooltip: {
									show: false
								}
							}],
							// 散点图配置
							series: serData
						};
						myChart.setOption(option);
						
						window.addEventListener('resize', function() {
						  // 在这里重新调整图表的尺寸
						  myChart.resize();
						});
						
						// 绑定鼠标悬停事件
						myChart.on('mouseover', 'series', (params) => {
							if (params.componentType === 'series' && params.seriesType === 'effectScatter') {
								// 处理鼠标悬停事件
								var selectedDataIndex = -1; // 记录当前选中的散点索引
								var seriesIndex = params.seriesIndex;
								var dataIndex = params.dataIndex;
								
								if (selectedDataIndex === dataIndex) {
									// 如果当前点击的散点已经被选中，则隐藏标签
									selectedDataIndex = -1;
									myChart.setOption({
										series: [{
											label: {
												show: false
											}
										}]
									});
								} else {
									// 如果当前点击的散点未被选中，则显示标签
									selectedDataIndex = dataIndex;
									myChart.setOption({
										series: [{
											label: {
												show: true,
												formatter: '{b}', // 可根据需要修改标签内容
												position: 'top',
												fontSize: 12
											},
											emphasis: {
												label: {
													show: true // 强调状态下仍然显示标签
												}
											},
											data: option.series[seriesIndex].data.map(function(item,
												index) {
													
												return {
													...item,
													label: {
														show: index === dataIndex // 只有当前选中的散点才显示标签
													},
													itemStyle: {
														normal: {
															color: index==dataIndex?'#d00':'#fff',
															shadowBlur: 10,
															shadowColor: '#333'
														}
													},
													
												};
											})
										}]
									});
								}
							}
						});
						
						
						// 绑定点击事件
						myChart.on('click', (params) => {
							this.$emit('changename', {
								name: '云端委员驿站联盟>委员驿站详情',
								id: params.data.id
							})
						})
						setTimeout(() => {
							myChart.setOption({
								series: [{
									label: {
										show: true,
										formatter: '{b}', // 可根据需要修改标签内容
										position: 'top',
										fontSize: 12
									},
									emphasis: {
										label: {
											show: true // 强调状态下仍然显示标签
										}
									},
									data: option.series[0].data.map((item, index) => {
										return {
											...item,
											label: {
												show: item.id == 21 // 只有当前选中的散点才显示标签
											},
											itemStyle: {
												normal: {
													color: item.id == 21 ? '#d00' : '#fff',
													shadowBlur: 10,
													shadowColor: '#333'
												}
											},
										};
									})
								}]
							});
						})

				})


		},
		godetail(item) {
			this.$emit('changename', {
				name: '云端委员驿站联盟>委员驿站详情',
				id: item.id
			})
		}

	}
	}
</script>

<style lang="scss" scoped>
	.echbox {
		background-image: url('../../../assets/echartbg.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
</style>